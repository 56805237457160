.fade-enter {
  .test-container > div {
    //new page entering, show it on top
    z-index: 10;
    opacity: 0;
    visibility: hidden;

    &.up { transform: translateY(10vh); }
    &.left { transform: translateX(10vh); }
  }

  //Enter is active (Page has finished transitioning) make it settle down
  &.fade-enter-active .test-container > div {
    transform: translate(0, 0);
    transition: all 600ms ease-in-out;
    opacity: 1;
    visibility: visible;
  }
}

.fade-exit {
  //old page exiting, hides it underneath (bottom)
  z-index: 1;
  opacity: 0;
  display: none;
}