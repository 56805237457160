$meter-color: #EC8A6F;

.meter {
  width: 100%;
  height: 0.6rem;
  background: #ffffff;
  border-radius: 0 0.2rem 0.2rem 0;
  position: relative;
  margin-bottom: 1rem;

  .meter-value {
    height: 100%;
    background: $meter-color;
    z-index: 2;
    position: absolute;
  }

  &:before {
    content: '';
    width: 1rem;
    height: 1rem;
    background: $meter-color;
    position: absolute;
    left: -0.15rem;
    top: -0.2rem;
    z-index: 1;
    border-radius: 1rem;
  }

  .meter-caption {
    position: absolute;
    left: 1rem;
    bottom: -0.7rem;
    color: white;
    font-size: 0.6rem;
    z-index: 2
  }

  &.muted {
    .meter-value,
    &:before {
      background: lighten($meter-color, 17);
    }
  }

  &.large {
    height: 1rem;

    &:before {
      width: 1.4rem;
      height: 1.4rem;
    }
    .meter-caption {
      left: auto;
      right: 0.5rem;
      bottom: auto;
      font-size: 0.9rem;
      color: #555;
    }
  }
}