$meter-color: rgb(156,16,6);

.page-history {
  .btn-home {margin-top: 1rem;}

  .history-table {

    .result-row {
      display: grid;
      padding: 1rem;
      text-decoration: none;

      &:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.1);
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    .date-cell, .meter-cell, .total-cell {grid-column: 1 / span 1;}
    .link-indicator { grid-column: 2 / span 1; grid-row: 1 / span 3;}
    .date-cell {
      grid-row: 1 / span 1;
      text-align: left;
      font-size: 1.2rem;
      margin-bottom: 5px;
      font-weight: 700;
      text-decoration: underline;
    }
    .meter-cell {
      grid-row: 2 / span 1;
    }
    .total-cell {
      grid-row: 3 / span 1;
      text-align: right;
    }
    .link-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
