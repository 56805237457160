a {
  header & {
    color: var(--text-color-dark);
  }
  main & {
    color: var(--text-color-light);
  }
}
a.icon-link {
  display: inline-flex;
  line-height: 24px;

  svg { margin-right: 0.25rem; }
}

.text-center { text-align: center; }
.text-em { font-style: italic; }
.text-bold { font-weight: bold; }
.text-underline { text-decoration: underline; }