button {
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  background: none;
  color: var(--text-color-light);
  font-family: var(--body-font);
  font-size: 1.25rem;
  cursor: pointer;
  text-align: center;
}
.btn {
  display: inline-block;
  border: solid 1px var(--button-bg-color);
  border-radius: 100px;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: var(--button-bg-color);
  color: var(--text-color-light);
  font-family: var(--body-font);
  font-size: 1.25rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,
  transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover,
  &:focus {
    background: darken($button-bg-color, 5%);
    border-color: darken($button-bg-color, 5%);
    box-shadow: none;
  }

  &:focus {
    border-color: #fff;
    outline: none;
  }

  &:active {
    transform: scale(0.99);
  }

  &:disabled {
    background-color: transparent;
    color: #d3d3d3;
    border-color: #d3d3d3;
  }

  header & {
    box-shadow: rgba(0, 0, 0, 0.4) 2px 5px 10px 0px;
  }

  &.btn-accent {
    border: solid 1px var(--button-bg-color-accent);
    background: var(--button-bg-color-accent);
    color: var(--text-color-light);

    &.btn-outline {
      color: var(--button-bg-color-accent);
    }
  }
  &.btn-outline {
    background: transparent;
    border-width: 2px;
  }
}
