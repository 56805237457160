.page-home {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  footer {
    margin-bottom: 20px;
  }

  .history-link {
    font-size: 1.4rem;
    font-weight: 600;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: #FFB30F;
    }
  }

  .mmct-ref { font-style: italic; font-size: 0.8rem; }
  .about-link { font-size: 0.9rem; }
}