.page {
  position: relative;
  padding: 0 40px 0 40px;
}

@import 'home';
@import 'about';
@import 'test';
@import 'result';
@import 'history';
