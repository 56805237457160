@import url('https://fonts.googleapis.com/css2?family=Ravi+Prakash&display=swap');

$button-bg-color: #73BE41;
$water-color: #4376A5;
$accent-color: #FFB30F;

:root {
  --sky-color: #D4E8FF;
  --water-color: #{$water-color};

  --body-font: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  --button-bg-color: #{$button-bg-color};
  --button-bg-color-accent: #{$accent-color};
  --text-color-dark: #296600;
  --text-color-dark-alt: #338000;
  --text-color-light: #fff;
}

html, body, #root, .App {
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: var(--water-color);
}
html, body {
  font-family: var(--body-font);
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

/* header / sky */
header {
  position: relative;
  background-color: var(--sky-color);
  flex: 0;
  height: 60px;
  transition: height 2s ease-in-out, flex 2s ease-in-out;

  & h1, & h2, & button {
    opacity: 1;
    visibility: visible;
    transition:visibility 2s ease-in-out,opacity 2s ease-in-out;
  }

  h1 {
    font-family: 'Ravi Prakash', "Arial Rounded MT Bold", Arial, cursive;
    color: var(--text-color-dark);
    font-size: 4.5rem;
    text-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
    margin-bottom: 0;
  }
  h2 {
    color: var(--text-color-dark-alt);
    margin-top: 0;
    margin-bottom: 0;

    &:last-of-type {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  /* Page variations */
  .header-full & {
    height: 490px;
    flex: 0 1 490px;
  }
  .header-sm-in & {
    height: 60px;
    flex: 0 1 60px;
    transition: height 2s ease-in-out 1s, flex 2s ease-in-out 1s;
    padding-bottom: 0;
    & h1, & h2, & .btn {
      visibility: hidden;
      opacity: 0;
    }
  }
  .header-sm-out & {
    height: 300px;
    flex: 0 1 300px;

    & h2, & .btn {
      visibility: hidden;
      opacity: 0;
    }
  }
}

/* main / water */
main {
  color: var(--text-color-light);
  background-color: var(--water-color);
  flex: 1 1 auto;
  z-index: 1;
  position: relative;
  padding-top: 1rem;
  transition: flex 1s ease-in-out;

  &:before {
    background-image: url("./images/wave.svg");
    content: '';
    width: 110vw;
    height: 30px;
    position: absolute;
    left: 0;
    top: -29px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  main:before {
    animation: waves-oscillate infinite 10s ease-in-out alternate;
  }
}

@keyframes waves-oscillate {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-10vw);
  }
}

@import 'sass/buttons';
@import 'sass/dive-platform';
@import 'sass/frog';
@import 'sass/transitionFade';
@import 'sass/transitionSlide';
@import 'sass/typography';

@import 'sass/components';
@import 'sass/pages';
