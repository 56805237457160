.page-test {
  text-align: left;

  nav {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 1rem;

    .nav-title {
      flex: 1;
      text-align: center;
      font-weight: 500;
      text-transform: uppercase;
    }

    .progress {
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background: lighten($water-color, 10%);

      .progress-bar {
        height: 100%;
        background: var(--sky-color);
        transition: width 500ms ease-in-out;
      }
    }
  }
  .test-container {
    max-width: 500px;
    margin: 0 auto;

    h3 {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .option-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    .option-item {
      height: 50px;
      display: flex;
      align-items: center;

      input { display: none; }

      svg { opacity: 0.15; }
      input:checked + svg { opacity: 1; }

      .option-name {
        margin-left: 1rem;
      }
    }
  }

  .exit-link {
    margin-top: 1rem;
  }
}