.login-prompt {
  margin-top: 1rem;

  .welcome {
    margin-bottom: 0.75rem;
  }
  .username { font-weight: bold; padding-bottom: 0.5rem; }
  .logout-link {
    font-size: 1rem;
    color: var(--water-color);
    text-decoration: underline;
    //font-weight: 600;
  }
}