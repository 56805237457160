.page-result {
  display: flex;
  flex-direction: column;
  align-items: center;

  .results-container {
    display: grid;
    grid-template-columns: 40% 40% 20%;
    justify-content: center;
    justify-items: flex-start;
    grid-gap: 1rem;
    width: 100%;
    max-width: 500px;
    margin: 0 auto 3rem;

    .group-name { text-align: left; }
    .group-value { justify-self: stretch; }
    .group-total { justify-self: flex-end; }
  }

  .btn { margin-bottom: 1rem;}
}
