.page-container,
.page-container > div,
.page-container > div > .page {
  height: 100%;
}
.page-container > div {
  position: relative;

  &.pageSlider-enter > .page,
  &.pageSlider-exit > .page {
    position: absolute;
    width: calc(100% - 80px);
    height: 100%;
    left: 0;
    top: 0;
  }
}
//Enter is active (Page has finished transitioning) make it settle down
.pageSlider-enter.pageSlider-enter-active > .page {
  transform: translate3d(0, 0, 0);
  //Apply all transitions
  transition: all 800ms ease-in-out;
}
.pageSlider-enter {
  //new page entering, show it on top
  & > .page {
    z-index: 10;
  }
  //LEFT transition transform 100% to the right
  &.left > .page {
    transform: translate3d(100%, 0, 0);
  }
  //RIGHT transition transform -100% to the left
  &.right > .page {
    transform: translate3d(-100%, 0, 0);
  }

  &.pageSlider-enter-active {
    & > .page {
      transition: all 800ms ease-in-out 1s;
      transform: translate3d(0, 0, 0);

      &.page-test {
        transition-delay: 3s;
      }
    }
  }
}

.pageSlider-exit {
  //old page exiting, hides it underneath (bottom)
  & > .page {
    z-index: 1;
    transform: translate3d(0, 0, 0);
  }

  &.pageSlider-exit-active {
    & > .page {
      transition: all 500ms ease-in-out;
    }
    &.left > .page {
      transform: translate3d(-100%, 0, 0);
    }
    &.right > .page {
      transform: translate3d(100%, 0, 0);
    }
  }
}