$frog-start-x: 10vw;
$frog-start-y: -165px;
$frog-end-x: 20vw;
$frog-end-y: -80px;

.frog {
  position: absolute;
  left: $frog-start-x;
  top: $frog-start-y;
  width: 60px;
  transition: top 2s cubic-bezier(0.175, 0.885, 0.320, 1.275), left 2s linear;
}
.frog.swimming {
  transition: top 2s cubic-bezier(0.600, -0.280, 0.735, 0.045), left 2s linear;
  left: $frog-end-x;
  top: $frog-end-y;
}
@media (prefers-reduced-motion: no-preference) {
  .frog.swimming {
    animation: frog-swim infinite 8s ease-in;
  }
}

@keyframes frog-swim {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0.5vw, -1px);
  }
  50% {
    transform: translate(0, 2px);
  }
  75% {
    transform: translate(-0.5vw, -1px);
  }
  100% {
    transform: translate(0, 0);
  }
}
